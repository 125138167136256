#help-email {
  &:hover {
    color: #5000a2 !important;

    & img {
      // https://codepen.io/sosuke/pen/Pjoqqp
      filter: invert(17%) sepia(91%) saturate(6005%) hue-rotate(273deg) brightness(61%)
        contrast(131%);
    }
  }
}

.doc_summ_table {
  .ant-table-thead > tr > th {
    font-weight: 600;
    background: #f4f6f8;
    border-bottom: 1px solid #e0e0e0;

    &:not(:last-child) {
      border-right: 1px solid #e0e0e0;
    }
  }
  .ant-pagination {
    padding-top: 15px;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    top: 62%;
    height: 3em;
  }
}

.document-table {
  tr,
  td {
    padding: 10px 16px;
  }
  thead {
    th {
      font-weight: bold !important;
    }
  }
  tbody {
    td {
      font-size: 12px !important;
    }
  }
  margin-top: 10px;

  tr.selected td {
    background: #efeffd;
  }
}

.invite-component-input {
  height: 72px;
  padding-top: 27px;
  padding-bottom: 26px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #c4c4c4;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.0025em;
  }
}

.email-container {
  background: #f5f5f5;
  border: 0;
  border-radius: 8px;
  padding-left: 24px;
  padding-right: 24px;
  width: 474px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.0025em;
  color: #1b1b1c;
}

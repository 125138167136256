.update-error-modal {
  .ant {
    &-modal {
      &-content {
        width: 408px;
        border: 1px solid #E9EBF0;
        border-radius: 12px;
        box-shadow: 0px 131px 319px rgba(0, 0, 0, 0.07), 0px 54.7287px 133.271px rgba(0, 0, 0, 0.0465315), 0px 29.2606px 71.2528px rgba(0, 0, 0, 0.0386802), 0px 16.4032px 39.9438px rgba(0, 0, 0, 0.0325971), 0px 8.71163px 21.2138px rgba(0, 0, 0, 0.0264921), 0px 3.62511px 8.82755px rgba(0, 0, 0, 0.0186043);
      }
    }
  }

  &-btn {
    height: 40px;
    width: 45px;
    border-radius: 4px;
    border: none;
    background-color: #029FDD;

    color: white;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.0025em;
  }

  &-column {
    display: flex;
    flex-flow: column nowrap;
  }
}

.close-icon:hover {
  filter: invert(17%) sepia(91%) saturate(6005%) hue-rotate(273deg) brightness(61%) contrast(131%);
}

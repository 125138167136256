.task-card-container {
  height: 77px;
  width: 100%;
  border-bottom: solid 1px #e0e0e0;
  padding: 24px;
  position: relative;

  .row {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
    // position: absolute;
    width: 100%;
    // left: 0;
    bottom: 24px;
    padding-right: 24px;
  }

  .item {
    // display: inline-block;
    flex: 1;
  }

  .right {
    align-items: flex-end;
    text-align: right;
  }

  .item-title {
    font-size: 10px;
    line-height: 14px;
    color: #24282d;
  }

  .item-value {
    font-size: 12px;
    line-height: 22px;
    // color: rgba(0, 0, 0, 0.7);
    font-weight: 400;
    color: #666666;
  }

  .item-datepicker {
    margin-top: -2px;
    padding: 0;
    input::placeholder,
    span {
      color: #666666;
      // color: #000;
      text-align: right;
      font-size: 12px;
    }
    input {
      margin-right: 6px;
      width: 140px;
      font-size: 12px;
    }
    input::placeholder {
      font-size: 12px;
      line-height: 22px;
      color: #666666;
      font-weight: 400;
    }
  }

  .description {
    font-size: 13px;
    line-height: 18px;
    color: #666666;
    width: 80%;
    margin-top: 2px;
    // font-weight: 400;
    // padding-left: 40px;
  }

  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.004em;
    margin-bottom: 0;
  }

  input {
    border: none;
    font-family: "Open Sans";
    font-style: normal;
  }

  .ant-picker-input > input {
    text-align: right;
    // padding-right: 4px;
  }

  .checkmark {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.task-card-container:hover {
  background-color: #f4f6f8;
}

.user-icon {
  margin-left: 6px;
  margin-right: -3.5px;
  margin-top: -2px;
  svg {
    width: 20px;
    height: 20px;
    // color: rgba(0, 0, 0, 0.85);
  }
}

.new-task {
  background-color: #efeffd;
  height: 128px !important;
  margin-bottom: 10px;

  .item-datepicker {
    input::placeholder,
    span {
      color: rgba(0, 0, 0, 0.85);
    }
    input::placeholder {
      color: #bebfbf;
    }
  }

  .user-icon {
    margin-left: -5px;
    margin-right: 5px;
  }

  .item-value {
    color: rgba(0, 0, 0, 0.85) !important;
  }
}

.new-task:hover {
  background-color: #efeffd;
}

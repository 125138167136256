@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css");
:root {
  --primary: #5000a2;
  --primaryLight: #efeffd;
  --secondary: #1860e2;
  --secondaryLight: #5288e9;
  --title: #1b1b1c;
  --valid: #31d0aa;
  --colorBody: #4a4a68;
  --colorBorder: #e0e0e0;
  --colorHelper: #8c8ca1;
  --colorAccent: #ecf1f4;
  --colorLight: #fafcfe;
  --boxshadow: rgba(0, 0, 0, 0.12) 0px 2px 7px;
  --transition: all 200ms;
}
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
}
.cur-pointer {
  cursor: pointer;
}
.common {
  &_brand {
    &_logo {
      // transition: all 0.2s;

      a {
        // transition: all 0.2s;
        max-width: 53px;
        display: block;
        img {
          transition: var(--transition);
        }
      }
    }
  }
  &_header {
    position: sticky;
    top: 0;
    left: 200px;
    width: calc(100% - 200px);
    z-index: 500;
    transition: all 0.2s;
    background: #fff;
    border-bottom: 1px solid var(--colorAccent);
    // background-image: linear-gradient(to right, #5000a2 0%, #1860e2 51%, #5000a2 100%);

    .logo {
      max-width: 175px;
      width: 52px;
    }
    &_wrp {
      height: 70px;
      // .trigger {
      //   display: inline-block;
      //   vertical-align: middle;
      // }
      &_title {
        display: inline-block;
        vertical-align: middle;
        h3 {
          font-size: 24px !important;
          line-height: 33px;
          padding-left: 5px;
          font-weight: 600;
          margin: 0;
          transition: var(--transition);
        }
      }
    }
    &.closed {
      left: 86px;
      width: calc(100% - 86px);
      .common_header_wrp_title {
        h3 {
          padding-left: 20px;
        }
      }
      .common_aside_toggler {
        left: 5px;
      }
    }
    &_user {
      &_toggle {
        display: inline-block;
        vertical-align: middle;
        &--btn {
          background-color: var(--secondary);
          // color: var(--primary);
          color: #fff;
          font-size: 1rem;
          padding: 10px;
          border-radius: 50px;
          height: 48px;
          width: 48px;
          line-height: 1px;
          cursor: pointer;
          text-align: center;
          transition: var(--transition);
          span {
            font-size: 1.1rem;
            letter-spacing: 1px;
            font-weight: bold;
            line-height: 1;
            text-transform: uppercase;
            padding: 6px 0;
            display: block;
          }
          &.ant-dropdown-open {
            border-radius: 50% 50% 0 50%;
          }
          &:hover {
            background-color: var(--secondary);
            color: #fff;
          }
        }
      }
    }
    &_create {
      border-radius: 5px;
      width: 200px;
      &_modal {
        .ant {
          &-modal {
            &-header {
              background: linear-gradient(90deg, #f8f0fe 0%, #e4eefd 100%);
            }
            &-body {
              max-height: calc(100vh - 150px);
              overflow-y: scroll;
              -webkit-overflow-scrolling: touch;
            }
          }
        }
      }
      &_form {
        .ant-form-item {
          &-label {
            padding-bottom: 2px;
            label {
              color: #888;
            }
            .ant-form-item-required {
              &::before {
                display: none !important;
              }
              &::after {
                display: block;
                content: "";
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #ff0000;
                margin-left: 5px;
              }
            }
          }
          &-control {
            input,
            .ant-select-selector,
            textarea,
            .ant-picker {
              border-radius: 5px;
              border-color: var(--colorBorder);
            }
          }
        }
      }
    }
  }
  &_footer {
    padding: 0 16px 16px 16px;
    // background: #f0f0f0;
  }
  &_aside {
    width: 100%;
    // height: calc(100vh - 64px);
    height: 100vh;
    position: fixed;
    border-right: solid 1px var(--colorAccent);
    top: 0;
    left: 0;
    &_toggler {
      position: absolute;
      left: -26px;
      top: 21px;
      line-height: 28px;
      cursor: pointer;
      transition: var(--transition);
      &:hover {
        color: var(--primary);
      }
    }
    .ant-menu {
      border: none;
      &-item {
        height: 44px;
        color: #666;
        &:hover::after {
          transform: scaleY(1);
          opacity: 1;
        }
        a {
          color: #666;
        }
        &-selected {
          background: var(--primaryLight) !important;
          color: var(--primary);
          .active {
            color: var(--primary);
          }
        }
      }
    }
    .ant-layout-sider {
      &-children {
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
          width: 4px;
        }
        &::-webkit-scrollbar-thumb {
          width: 3px;
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
      &-trigger {
        background: var(--primary);
        color: #fff;
      }
      // &-collapsed{
      //   flex: 0 0 86px;
      // max-width: 86px;
      // min-width: 86px;
      // width: 80px;
      // }
    }
  }
  &_content {
    &_wrapper {
      background: #fff;
      margin-left: 200px;
      width: calc(100vw - 200px);
      transition: all 0.2s;
      &.closed {
        width: calc(100vw - 90px);
        margin-left: 86px;
      }
      &_holder {
        min-height: calc(100vh - 134px);
        margin: 8px 16px;
      }
    }
  }
  &_bg {
    background: #fff;
  }

  &_card {
    background: #fff;
    border-radius: 10px;
    box-shadow: var(--boxshadow);
    // margin: 16px;
    padding: 16px;
    min-height: 100px;
    margin: 8px 0;
    &.bg-grd {
      background-size: 200%;
      background-image: linear-gradient(to right, #cc2b5e 0%, #753a88 51%, #cc2b5e 100%);
    }
  }
}

.custom {
  &_select {
    width: 100%;
    .ant {
      &-select {
        &-selector {
          background: var(--colorAccent) !important;
          border: none !important;
          border-radius: 4px !important;
          font-size: 14px;
          &:focus,
          &:active {
            box-shadow: none;
          }
        }
      }
    }
    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      box-shadow: none !important;
    }
  }
  &_input {
    &_1 {
      background: var(--colorAccent);
      // border: none !important;
      border-color: var(--colorAccent);
      box-shadow: none;
      border-radius: 4px;
      input {
        background: none;
        font-size: 14px;
      }
      &:hover {
        border-color: var(--colorAccent) !important;
      }
      &:focus,
      &:active {
        outline: none;
      }
    }
    &.outlined {
      background: transparent !important;
      border-radius: 6px;
      border-color: #fff;
      input {
        background: transparent !important;
        font-size: 0.9rem;
      }
      .ant-select-selector {
        background: transparent !important;
        border-radius: 6px;
      }
    }
    &.light {
      input {
        color: #fff;
        &::placeholder {
          color: #fff;
        }
      }
      .ant-input {
        &-prefix {
          color: #fff;
          margin-right: 10px;
        }
        &-suffix {
          span {
            color: #fff;
            svg {
              path {
                fill: #fff;
              }
            }
          }
        }
      }
      .ant-select-arrow,
      .ant-select-selection-item {
        color: #fff;
      }
    }
  }
  &_statistic {
    .ant-statistic-content {
      &-prefix {
        margin: 0;
      }
      font-size: 14px;
    }
  }
  &_formitem {
    &.ant-form-item-has-error {
      .ant-form-item-explain {
        text-align: left;
      }
    }
    &.light {
      .ant-form-item-explain-error {
        color: #e0e0e0;
        font-size: 0.75rem;
      }
    }
  }
  &_btn {
    border-radius: 6px;
    &.outlined {
      background: none;
      border: solid 1px var(--primary);
      color: var(--primary);
      &:hover {
        background: var(--primaryLight);
      }
    }
  }
}

.ant-drawer {
  margin-top: 0 !important;
}

.hide-disable {
  .ant-select-selector {
    background-color: #fff;
    color: #000;
  }
}

.font-12 {
  label,
  span,
  p {
    font-size: 12px;
  }
}

.block-menu-trigger {
  margin-top: -2px !important;
}

.activity-data-length {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 8px;
  width: 20px;
  height: 20px;
  background: #efeffd;
  border-radius: 48px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.004em;
  color: #000000;

  margin-left: 10px;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

.item_hover:hover {
  background-color: #f4f6f8;
}

.ant-select.ant-select-lg.ant-select-in-form-item.dropdown_search_select.ant-select-single.ant-select-show-arrow.ant-select-show-search {
  span.ant-select-selection-placeholder {
    color: #bfbfbf !important;
  }
}

iframe {
  // width: 0 !important;
  // height: 0 !important;
}

// IMPORTANT:: THIS COMPONENT/FILE NEED TO BE REMOVED IN FUTURE
@keyframes loadingspinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ant-drawer {
  &-header {
    padding: 0px;

    &-title {
      position: absolute;
      right: 10px;
      top: 30px;

      .anticon {
        color: #333;
      }
    }
  }

  &-body {
    padding: 0px;
  }
}

.pod_drawer {
  position: relative;
  &.pod_pipeline_drawer {
    height: 100%;
    display: flex;
    flex-direction: column;

    .pod_drawer_head {
      display: flex;
      align-items: center;

      .close-icon {
        position: relative;
        inset: 0;
        margin: 12px 16px auto 0px;
        height: 12px;

        img {
          height: 12px;
          cursor: pointer;
          display: block;
        }
      }

      & > div:not(.close-icon) {
        flex: 1;
      }
    }
  }

  &_head {
    background: linear-gradient(90deg, #f8f0fe 0%, #e4eefd 100%);
    padding: 25px;
    position: relative;

    &--close {
      position: absolute;
      right: 15px;
      top: 30px;
      color: var(--colorBody);
    }
    &--title {
      font-weight: 600;
      color: #333333;
      display: flex;
      align-items: center;
      margin-bottom: 0px;
      flex: 1;

      span {
        max-width: 280px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        white-space: nowrap;
        font-size: 24px;
        line-height: 33px;
        margin-right: 6px;
      }
      > div {
        display: inline-block;
      }
    }

    &--subtitle {
      font-size: 14px;
      line-height: 19px;
      color: #1b1b1c;
      padding-top: 6px;
    }

    .external-icon-wrapper {
      cursor: pointer;
      padding: 0px;
      margin-left: 8px;

      img {
        width: 36px;
      }
    }
  }

  .pod_opportunity_content {
    .ant-tabs.ant-tabs-top {
      height: calc(100vh - 102px);
    }
  }

  .pod_contact_content,
  .pod_lead_content,
  .pod_account_content {
    .ant-tabs.ant-tabs-top {
      height: calc(100vh - 315px);
    }
  }

  .ant-tabs-content-holder {
    height: calc(100% - 70px);

    .ant-tabs-content.ant-tabs-content-top,
    .ant-form.ant-form-vertical {
      height: 100%;
    }
  }

  &_next {
    background: #f5f5f5;
    border-radius: 8px;
    padding: 16px;
    margin: 16px 25px;

    &--title {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #0e0e2c;
      margin-bottom: 12px;
    }

    &--text {
      font-size: 14px;
      line-height: 19px;
      color: #0e0e2c;

      strong {
        font-weight: 600;
      }
    }
  }
}

.ant-tabs {
  &-nav {
    padding: 0 25px;
    // background: #f5f5f5;
  }

  &-tab {
    font-size: 16px;
    line-height: 22px;
    color: #666666;
    height: 54px;

    &:hover {
      color: #1b1b1c;
    }

    &-active {
      .ant-tabs-tab-btn {
        color: #1b1b1c !important;
      }
    }
  }
}

.ant-tabs-content-holder {
  padding: 0 25px;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 4px;
  border-radius: 2px;
}

.pod_contact_list {
  height: calc(100% - 60px);
  overflow-y: auto;
  margin-right: -12px;
  padding-right: 8px;
  position: relative;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 2px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-button {
    height: 12px;
  }

  &_each {
    background: #f4f6f8;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 10px;

    &.pod_primary_contact {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 2px;
        height: 80%;
        background: #31d0aa;
        left: 0;
        top: 10%;
        border-radius: 4px;
      }
    }

    &--title {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #1b1b1c;
      cursor: pointer;
    }

    &--dropdown {
      .ant-select-single.ant-select-open .ant-select-selection-item {
        color: #1b1b1c;
      }

      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        border: none;
        box-shadow: none;
      }

      .ant-select {
        min-width: 150px;

        &-selector {
          font-size: 12px;
          line-height: 16px;
          color: #1b1b1c;
          border: none !important;
          background: transparent !important;
          text-align: right;

          &:focus {
            box-shadow: none;
            outline: none;
          }
        }
      }
    }

    &--email {
      font-size: 14px;
      line-height: 19px;
      color: #666666;
    }

    &--options {
      .anticon-down {
        display: none;
      }

      .pod_delete {
        padding-right: 10px;
      }
    }
  }
}

.pod_note_list {
  height: calc(100% - 60px);
  overflow-y: auto;
  margin-right: -12px;
  padding-right: 8px;

  &_each {
    cursor: pointer;

    &:not(:first-child) {
      margin-top: 8px;
    }

    &:hover {
      background: #f4f6f8;
    }

    border-bottom: 1px solid #e9ebf0;
    border-radius: 8px;
    padding: 16px;

    &--title {
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
    }

    &--modified {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #666666;
    }
  }
}

.pod_stage_syn {
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &--title {
    font-size: 16px;
    line-height: 22px;
    color: #1b1b1c;
  }

  .ant-rate {
    font-size: 0px;
  }
}

.syn_btn {
  // height: 35px;
  padding: 0 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #00a1e0;
  border-radius: 4px;
  border: none;

  &:hover {
    background: #0196d1;
  }

  img {
    margin-right: 6px;
  }
}

.ant-dropdown-menu-item:hover,
.ant-select-item.ant-select-item-option.ant-select-item-option-active {
  background: var(--colorLight);
}

.pod_btn_secondary {
  height: 38px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  color: var(--primary);
  border: var(--primary) 1px solid;
  border-radius: 4px;
  transition: var(--transition);

  &:hover {
    background: var(--primary);
    color: #fff;
  }
}

.max_width_320 {
  max-width: 320px;
}

.pod_form {
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 50px;

    .ant-select-selection-item {
      line-height: 48px;
    }
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  .ant-select-selector {
    height: 50px;
  }

  .ant-input-number-affix-wrapper {
    width: 100%;
  }

  label {
    font-size: 10px;
    line-height: 14px;
    color: #666666;
  }

  .ant-form-item-label {
    padding-bottom: 4px;
  }

  input {
    height: 50px;
    width: 100%;
    font-size: 14px;
    line-height: 19px;
    color: #1b1b1c;
  }

  .ant-picker {
    width: 100%;
  }
}

.pip_table_extras_addon--btn {
  border: 1px solid #e0e0e0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(.filters) {
    width: 40px;
    padding: 0;
  }
}

.pod_details_form {
  height: calc(100% - 60px);
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 2px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-button {
    height: 12px;
  }

  .ant-form-item-label {
    padding-bottom: 4px;
    label {
      font-size: 12px;
    }
  }

  &_item {
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
      margin-left: 8px;

      &.loading {
        animation-name: loadingspinner;
        animation-duration: 0.5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }
  }
}

.ant-drawer.ant-drawer-right.ant-drawer-open,
.ant-drawer.ant-drawer-right {
  transform: none !important;
}

.pod_assign_contact {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .pod_btn_secondary {
    margin-left: 10px;
  }

  &_list {
    padding: 0 12px;
    height: calc(100% - 64px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 2px;
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background: #c4c4c4;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-button {
      height: 12px;
    }

    &_each {
      margin-bottom: 20px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      &_v2 {
        background: #f4f6f8;
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 10px;

        &--title {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #1b1b1c;
          margin-bottom: 10px;
        }

        &--email {
          font-size: 14px;
          line-height: 19px;
          color: #666666;
        }
      }

      &--title {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #1b1b1c;
        margin-bottom: 6px;
      }

      &--info {
        font-size: 14px;
        line-height: 19px;
        color: #666666;
      }

      &--email {
        font-size: 10px;
        line-height: 14px;
        color: #666666;
      }
    }
  }
}

.pod_assign_contact_wrap {
  height: 100%;

  .ant-drawer-mask {
    display: none;
  }

  .ant-drawer {
    position: absolute;
    height: calc(100% + 15px);
    top: -15px;

    .ant-drawer-content-wrapper {
      width: 100% !important;
      position: absolute;
    }
  }

  .ant-drawer-header-title {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    right: auto;
    top: auto;
  }

  .ant-drawer-header {
    padding: 20px 0;
    border: none;

    .ant-drawer-title {
      font-size: 20px;
      line-height: 27px;
      color: #1b1b1c;
    }
  }

  .ant-drawer-body {
    overflow: hidden;
  }
}

.pod_select {
  .ant-select-selector {
    background: #f5f5f5 !important;
    height: 38px !important;

    .ant-select-selection-item {
      line-height: 38px;
    }
  }
}

.pod_task_list {
  margin-top: 10px;
  height: calc(100% - 60px);
  overflow-y: auto;
  margin-right: -12px;
  padding-right: 8px;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 2px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-button {
    height: 12px;
  }

  &_each {
    padding: 25px;
    border-bottom: #e0e0e0 1px solid;
    margin-bottom: 6px;
    position: relative;

    &.done {
      background: #f4f6f8;

      .check {
        background: #0e7537;
      }
    }

    .check {
      width: 14px;
      height: 14px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 25px;
      top: 30px;
      border: #000 1px solid;
      border-radius: 50%;
    }

    &--text {
      font-size: 13px;
      line-height: 18px;
      color: #333333;
    }

    &_info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;

      &--assign {
        span {
          font-size: 10px;
          line-height: 14px;
          color: #24282d;
          display: block;
          margin-bottom: 6px;
        }

        font-size: 12px;
        line-height: 16px;
        color: #24282d;

        .name {
          span {
            width: 24px;
            height: 24px;
            background: #1b61e9;
            border-radius: 50%;
            color: #fff;
            font-size: 10px;
            line-height: 14px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 4px;
          }
        }
      }

      &--date {
        text-align: right;

        > span {
          font-size: 10px;
          line-height: 14px;
          color: #24282d;
          display: block;
          margin-bottom: 6px;
        }

        font-size: 12px;
        line-height: 16px;
        color: #1b1b1c;
      }
    }
  }
}

.green_txt {
  color: #0e7537;
}

.pod_drawer_v2 {
  position: absolute;

  .pod_details_form {
    height: calc(100vh - 477px);
  }

  .pod_opp_list,
  .pod_assign_contact_list {
    height: calc(100vh - 477px);
    overflow-y: auto;
    margin-right: -12px;
    padding-right: 8px !important;

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 2px;
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background: #c4c4c4;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-button {
      height: 12px;
    }
  }

  .ant-drawer-mask {
    display: none;
  }

  .ant-drawer-title {
    display: none;
  }

  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.pod_drawer_head2 {
  background: linear-gradient(90deg, #f8f0fe 0%, #e4eefd 100%);

  &--title {
    height: 80px;
    display: flex;
    align-items: center;
    padding: 25px;

    .back {
      margin-right: 20px;
      display: inline-flex;
      cursor: pointer;
    }

    .name {
      display: inline-flex;
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      color: #333333;

      img {
        margin-right: 10px;
      }
    }
  }
}

.pod_opp_list {
  max-height: calc(100vh - 477px);
  overflow: auto;
  &_each {
    background: #f4f6f8;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--title {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #1b1b1c;
      max-width: 225px;
    }

    &--stage {
      text-align: right;
      font-size: 14px;
      line-height: 19px;

      > span {
        font-size: 10px;
        line-height: 14px;
        color: #000000;
      }
    }
  }
}

.pod_contact_card {
  padding: 20px;

  &_info {
    border-bottom: 1px solid #efeffd;
    padding-bottom: 15px;
    margin-bottom: 15px;

    &_each {
      margin-bottom: 25px;
    }

    &--label {
      font-size: 10px;
      line-height: 14px;
      color: #666666;
      margin-bottom: 2px;
    }

    &--title {
      font-size: 20px;
      line-height: 27px;
      color: #1b1b1c;
    }

    &--subtitle {
      font-size: 14px;
      line-height: 19px;
      color: #1b1b1c;
    }

    &--user {
      margin-bottom: 4px;
    }

    &_wrap {
      padding: 20px;
      border: 1px solid #efeffd;
      border-radius: 4px;
      position: relative;

      .bank {
        position: absolute;
        right: 20px;
        top: 20px;
        background: #f5f5f5;
        border: 1px solid #f5f5f5;
        border-radius: 4px;
        height: 32px;
        display: inline-flex;
        align-items: center;
        padding: 0 12px;
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #1b1b1c;

        img {
          margin-right: 8px;
          width: 12px;
        }
      }
    }

    &--profile {
      margin-bottom: 10px;
    }

    &--name {
      font-size: 20px;
      line-height: 27px;
      color: #1b1b1c;
      margin-bottom: 5px;
    }

    &--desn {
      font-size: 14px;
      line-height: 19px;
      color: #1b1b1c;
    }

    &--email,
    &--phn {
      font-size: 13px;
      line-height: 18px;
      color: #1b1b1c;

      img {
        margin-right: 6px;
      }
    }
  }
}

.ant-drawer-close {
  z-index: 2;
}

.close-icon {
  position: absolute;
  top: 28px;
  right: 28px;
  cursor: pointer;
}

.external-icon {
  width: 45px;
  cursor: pointer;
  // margin-left: 10px;
  align-self: flex-end;
  // margin-top: 2px;
}

.popover-replace {
  top: 351px;
  position: "absolute";
  right: 10px;
  width: 250px;

  .pip_col_customiser_list {
    max-height: calc(100vh - 550px) !important;
  }
}

.taskMgrTaskDscr {
  outline: none;
  border: none;
  position: absolute;
  left: 7.5%;
  width: 65%;
}

.newTaskTextArea {
  /*left: 1%;
  top: 5px;*/
  /*top: 10%;*/
  /*position: relative;*/
  /*top: 20%;*/
  /*width: 75%;*/
  background-color: transparent;
  outline: none;
  border: none;
  resize: none;
}

.VisibleExtDiv {
  left: 2.5%;
  display: block;
  position: relative;
  width: 95%;
  background-color: yellow;
  height: 32px;
}

.HiddenExtDiv {
  left: 2.5%;
  display: none;
  position: relative;
  width: 95%;
  background-color: yellow;
  height: 32px;
}

.newTaskAssignee {
  position: absolute;
  right: 56px;
  top: 42.5px;
  width: 15%;
  background-color: transparent;
  outline: none;
  border: none;
}

.expandedCard {
  height: 64px;
  background-color: white;
  border: 1px solid #f4f6f8;
  border-bottom: none;
  left: 2.5%;
  position: relative;
  width: 95%;
}

.collapsedCard {
  height: 64px;
  background-color: white;
  border: 1px solid #f4f6f8;
  left: 2.5%;
  position: relative;
  width: 95%;
}

#existingTaskAssignee::placeholder {
  color: black;
  opacity: 1;
}

.overdue-datepicker {
  input {
    color: #b20909;
  }
}

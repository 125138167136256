.subheader {
  border-bottom: 1px solid var(--colorAccent);
  padding: 8px 16px;
}

.grid-container {
  display: grid; /* This is a (hacky) way to make the .grid element size to fit its content */
  overflow: auto;
  width: 100%;
  height: calc(100vh - 203px);
}
.grid {
  display: flex;
  flex-wrap: nowrap;
}
.grid-col {
  width: 260px;
  min-width: 260px;
}

.grid-item--header {
  position: sticky;
  position: -webkit-sticky;
  background: #f4f6f8;
  top: 0;
  padding: 26px 15px !important;
  line-height: unset !important;

  p:first-child {
    color: #1b1b1c;
    font-weight: 600;
    font-size: 17px !important;
  }

  .grid-date-single {
    margin: 0 2px;
  }

  .grid-date-single span {
    display: block;
  }

  .grid-date-single span:first-child {
    color: #1b1b1c;
    font-weight: 600;
  }

  .grid-date-single span:last-child {
    color: #666666;
    font-weight: normal;
    font-size: 13px;
  }

  .grid-date-multiple span {
    color: #8cb0f1;
    margin: 0 13px;
  }
}

.grid-content div {
  margin-right: 5px;
}

.grid-col--fixed-left {
  position: sticky;
  left: 0;
  z-index: 999;
  background: white;
}
.grid-col--fixed-right {
  position: sticky;
  right: 0;
  z-index: 998;
  background: white;
  height: 480px;
}

.grid-item {
  height: 76px;
  line-height: 80px;
  padding: 0px 10px;
  box-shadow: inset -1px -1px 0px #e0e0e0;

  p {
    color: #666666;
    font-size: 14px;
  }
}

// .grid-item:hover {
//   background: #efeffd;
// }

.customer-icon:hover,
.email-icon:hover,
.internal-meetings-icon:hover {
  cursor: pointer;
}

.customer-icon {
  background: #1d7bff;
  border-radius: 50%;
  padding: 2px 7px 5px 7px;
  font-size: 16px;
  position: inherit;
  z-index: 10;
}

.email-icon {
  background: #ff0000;
  border-radius: 50%;
  padding: 2px 7px 5px 7px;
  font-size: 16px;
  position: inherit;
  z-index: 10;
}

.internal-meetings-icon {
  background: #7c40b9;
  border-radius: 50%;
  padding: 2px 7px 5px 7px;
  position: inherit;
  z-index: 10;
}

.telephone-icon {
  background: #31d0aa;
  border-radius: 50%;
  padding: 2px 7px 5px 7px;
  font-size: 16px;
  position: inherit;
  z-index: 10;
}

.empty {
  color: #fff;
  background: #fff;
  cursor: not-allowed !important;
  margin-left: 5.5px;
}

.activity_page {
  height: calc(100vh - 72px);
  width: calc(100% + 32px);
  margin: -8px -16px;

  &_content {
    width: 100%;
    height: calc(100% - 48px);
    padding: 0px 16px 16px;
    position: relative;
  }
  &_heading2 {
    align-items: center;
    span {
      font-weight: 600;
      font-style: normal;
      color: #666666;
      line-height: 19.07%;
      font-size: 15px;
    }
  }
  &_heading3 {
    align-items: center;
    span {
      font-weight: 400;
      font-style: normal;
      color: #7d828c;
      line-height: 19.07%;
      font-size: 14px;
    }
  }
  &_insights {
    &--icon {
      position: relative;
      width: 26px;
      height: 26px;
      margin-left: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      line-height: initial;
      padding: 4px;
      border-radius: 2px;
      color: #777;

      &:hover,
      &.ant-dropdown-open,
      &.ant-popover-open {
        color: var(--primary);
        background: var(--primaryLight);
      }

      div > svg {
        width: 26px;
        height: 26px;
      }
    }
  }
}

.ant-tabs-nav {
  background: #fff;
}

.google-meet-link-title,
.google-meet-link,
.google-phone-title,
.google-phone {
  color: #1960e2;
}

.google-meet-link-title,
.google-phone-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.google-meet-link,
.google-phone {
  font-size: 12px;
}

.recipient-list ul {
  list-style-type: none;
  padding-left: 20px;
  margin-left: 30px;
  li {
    padding: 8px 0;
    color: #666666;
    font-size: 14px;

    span {
      display: block;
      font-size: 12px;
      color: #999999;
    }
  }
}

.recipient-list {
  width: 100%;
  margin-top: -12px;
  margin-left: -23px;
}

.recipient-list-ul {
  margin: 0;
  max-height: 280px;
  overflow-y: scroll;
}

.recipient-list-heading {
  border-bottom: 1px solid #e9ebf0;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  width: 100%;
  height: max-content;
  color: #1b1b1c;
  font-weight: 400;
  font-style: normal;
  line-height: 17.7px;
}

.dropdown-list-item {
  color: #666666;
  font-weight: 500;
}

.ant-dropdown-menu-item {
  border: 1px solid #f4f6f8 !important;
}

.single-item-container {
  position: relative;
}

.pointer-more-items {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  color: #5000a2;
  background: #5000a2;
  border-radius: 50%;
  top: 25px;
  left: 20px;
  z-index: 11;
}

.caller-name,
.receiver-name {
  font-weight: 600;
  font-size: 16px;
}

.small-text-phone {
  font-size: 13px;
}

.email-body-text {
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  line-height: 24px;
  color: #333333;
}

.ant-drawer-close {
  font-size: 14px;
  margin-top: -8px;
}

.pod_drawer.customer_drawer {
  .pod_drawer_head--title {
    position: relative;
  }

  .pod_drawer_meeting_title {
    font-size: 16px !important;
    line-height: 22px !important;
    font-weight: 600 !important;
    font-style: normal;
    padding: 0 10px;
    text-transform: capitalize;
  }

  .pod_drawer_head--subtitle {
    padding-left: 0;
    padding-top: 15px;
  }

  .customer-icon,
  .email-icon,
  .telephone-icon,
  .internal-meetings-icon {
    z-index: 10;
    width: 24px;
    height: 24px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    padding: 4px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.opportunity_selectbox {
  margin-left: 8px;
}

.crown-icon svg {
  width: 32px;
  height: 32px;
  margin-left: -4px !important;
}
.google-meet-icon svg {
  width: 26px;
  height: 40px;
}

.phone-svg-icon svg {
  width: 26px;
  height: 40px;
}

.meeting_date_time_container {
  padding: 20px 20px 0 16px;
  margin: 0;
  align-items: center;
}

.custom-close-btn {
  margin-top: -5px;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
}

.meeting_date_time_container {
  div {
    p {
      span:first-child {
        padding-right: 10px;
      }
      span:last-child {
        padding-left: 10px;
      }
    }
  }
}

.row-items {
  align-items: center;
}

.border-bottom-silver {
  border-bottom: "1px solid #E9EBF0";
}

.participants-icon {
  width: 40px;
  height: 15px;
}

.customer_drawer {
  .ant-tabs-content-holder {
    padding: 0;
  }
  .ant-tabs-tabpane {
    padding: 0 25px;
  }

  .open-with-calendar-box {
    margin-left: -25px;
    padding: 20px !important;
  }
}

.description-area {
  padding: 20px 0;
}

.border-separator {
  border-top: 1px solid#E9EBF0;
  margin-left: -25px;
  width: 400px;
  height: 0.7px;
}

.recipient-list-title {
  border-bottom: 1px solid#E9EBF0;
  border-top: 1px solid#E9EBF0;
  padding: 10px 0;
}

.no-top-border {
  border-top: none;
}

.btn-container,
.datepicker-btn-cstm {
  background-color: #f5f5f5;
}

.datepicker-btn-cstm:hover,
.datepicker-btn-cstm:active,
.datepicker-btn-cstm:focus {
  background-color: #f5f5f5;
}

.btn-container {
  border-radius: 6px;
}

.dashed-line {
  position: absolute;
  height: 400px;
  width: 2px;
  top: -320px;
  z-index: 9;
  left: 16px;
}

.stage-box {
  width: 50px;
  height: 22px;
  border-radius: 4px;
  background-color: black;
  position: absolute;
  left: -8px;
  z-index: 13;
}

.box-today {
  background-color: #5000a2;
  top: 84px;
  p {
    line-height: 22px;
    color: #fff;
    font-size: 10px;
    text-align: center;
  }
}

.box-stage {
  background-color: #1960e2;
  top: 10px;
  padding: 0 5px;
  overflow: hidden;
  p {
    line-height: 22px;
    color: #fff;
    font-size: 10px;
    text-align: center;
  }
}

.dashed-today {
  border: #5000a2 0.5px dashed;
}
.dashed-stage {
  border: #1960e2 0.5px dashed;
}

.pod_drawer_meeting_details_area {
  height: calc(100vh - 164px);
  overflow-y: scroll;
}

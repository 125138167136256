.actions-dropdown-container.templates-container {
  width: 350px;
  font-size: 14px;
  line-height: 19px;

  .ant-tabs-top {
    .ant-tabs-nav {
      background: var(--primaryLight);
      margin-bottom: 0px;

      .ant-tabs-nav-list {
        width: 100%;
        justify-content: space-between;

        .ant-tabs-tab {
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          height: 46px;

          .ant-tabs-tab-btn {
            color: #666666;
          }

          &.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: var(--primary) !important;
          }
        }

        .ant-tabs-ink-bar {
          height: 2px;
        }
      }
    }
  }

  .ant-tabs-content-holder {
    padding: 0px;
  }

  .pod-template-list {
    &-search {
      padding: 16px;
    }

    &-content {
      width: 100%;
      height: 276px;
      overflow-y: auto;
    }

    &-actions {
      border-top: 1px solid #e9ebf0;
      height: 46px;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 8px 12px;
      color: var(--primary);
    }
  }

  .actions-dropdown-item {
    height: 46px;
    display: flex;
    align-items: center;
    border-bottom: none;
    flex-direction: row;
    padding: 12px 16px;
    margin: 0;

    &:hover {
      background-color: var(--primaryLight);
      color: var(--title);
    }

    &-title {
      cursor: pointer;
    }
  }

  .actions-dropdown-icon-container {
    justify-content: center;
    display: flex;
    border-radius: 2px;

    svg {
      width: 24px;
      height: 24px;
    }

    &:hover {
      background: #f4f6f8;
    }
  }

  .create-template-icon {
    justify-content: center;
    display: flex;

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .options-item-text {
    line-height: 19px;
    font-size: 14px;
    font-weight: 500;
    flex: 1;
    margin: 0;
  }

  .no-result {
    padding: 10px;
    text-align: center;
  }

  .spin {
    width: 100%;
    margin: 10px 0;
  }

  .ant-dropdown-menu {
    padding: 0 !important;
    border-radius: 4px;
  }
}

.actions-dropdown-item-menu {
  width: 150px;
  border-radius: 4px;

  .ant-dropdown-menu-item {
    border: none !important;
    padding: 8px 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #666;

    &:hover {
      background: var(--primaryLight);
      color: var(--title);
    }
  }
}

p {
  width: max-content;
  height: max-content;
  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.005em;
  color: black;
}

.nav-btn {
  height: 54px;
  line-height: 46px;
  border-radius: 4px;
  border-color: #00a1e0;
  &:hover {
    border-color: #00a1e0;
  }
}

.welcome-column {
  display: flex;
  flex-flow: column nowrap;
}

.welcome-row {
  display: flex;
  flex-flow: row nowrap;
}

.page {
  width: 100vw;
  height: 100vh;

  p {
    display: inline-block;
  }
}

@media (max-width: 1149px) {
  #left-split {
    display: none;
  }

  #right-split {
    width: 100% !important;
  }
}

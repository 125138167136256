.pip_col {
  &_customiser {
    min-width: 200px;
    &--search {
      .ant-input-affix-wrapper {
        border-radius: 4px;
        border-color: var(--colorAccent);
      }
    }
    &_list {
      max-height: 400px;
      overflow-y: auto;
    }
  }
}

.kanban_board {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 195px;
  grid-column-gap: 16px;
  width: fit-content;
  min-width: 100%;
  padding-bottom: 8px;
  position: relative;

  &#kanban-board {
    height: calc(100vh - 240px);
    overflow-y: auto;
    padding-bottom: 0px;
    display: block;

    .infinite-scroll-component {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 195px;
      grid-column-gap: 16px;
      height: unset !important;
      overflow: unset !important;
    }
  }

  &_loader {
    position: absolute;
    top: 0;
    left: 0;
  }

  &_wrapper {
    width: 100%;
    overflow-x: auto;
    margin-top: 16px;
  }

  &_column {
    &_header {
      background: #efeffd;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 16px;

      .name {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: var(--primary);
        width: calc(100% - 16px);
      }

      .count {
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        margin-left: 8px;
      }
    }

    &_droppable {
      background: transparent;
      height: 100%;
      &.dragging {
        background: var(--primaryLight);
      }
    }
  }

  &_card {
    padding: 16px;
    border: 1px solid #efeffd;
    background: white;
    border-radius: 4px;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.12);
    margin-bottom: 8px;

    &:hover {
      background: #f5f5f5;
      border: 1px solid #e0e0e0;

      .action {
        visibility: visible;
      }
    }

    &.dragging {
      border: var(--primary);
    }

    .action {
      visibility: hidden;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      padding: 1px;
      width: 18px;
      height: 18px;
      cursor: pointer;

      svg {
        color: var(--primary);
      }

      &:hover {
        background: var(--primaryLight);
      }
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        cursor: pointer;
      }
    }

    .account_info {
      display: flex;
      align-items: center;
      margin-top: 4px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }

      .name {
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #54575d;
      }
    }

    .meta {
      margin-top: 16px;
      display: flex;
      align-items: flex-start;

      .block {
        flex: 1;

        .label {
          font-size: 10px;
          line-height: 14px;
          color: #54575d;
        }

        .value {
          font-weight: 600;
          font-size: 10px;
          line-height: 14px;
        }
      }

      .actions {
        margin-top: auto;
      }
    }
  }
}

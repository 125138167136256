.nav-btn {
  &-onboard {
    width: 217px;
    border-color: #5000a2;
    &:hover {
      border-color: #5000a2;
    }
  }
}

.circle {
  position: relative;
  height: 24px;
  width: 24px;
  background-color: #d3bfe8;
  border-radius: 50%;
  margin-right: 1.7vw;

  &-inner {
    @extend .circle;
    background-color: #5000a2;
    height: 8px;
    width: 8px;
    top: 50%;
    left: 50%;
    margin: -4px 0 0 -4px;
  }

  &-checked {
    @extend .circle;
    background-color: #5000a2;
    background-image: url("../../Images/checkmark.svg");
    background-repeat: no-repeat;
    background-position: 6px 6px;
  }
}

.line {
  border-left: 2px solid #d3bfe8;
  height: 64px;
  margin-left: 10px;

  &-traversed {
    @extend .line;
    border-left-color: #5000a2;
  }
}

.step {
  font-weight: 600;
  font-size: 24px;
  margin-top: 16px;
}

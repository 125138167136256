.dropdown-icon-container {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #5000a2;
  margin-top: -18px;
  margin-left: calc(100% - 38px);
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    margin-top: 5px;
  }
}

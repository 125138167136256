.pip_actions_insights {
  &.pipInsightsRender {
    .pipInsightsRender {
      &_childtitle {
        padding: 10px 16px 2px 16px;
        span {
          color: #000;
          background: var(--primaryLight);
          // margin-right: 15px;
          padding: 2px 12px;
          border-radius: 4px;
          font-size: 13px;
          display: inline-block;
          // svg{vertical-align: -1px;}
        }
      }
    }
    .pip_actions_insights_cards {
      &_each {
        border: none;
        border-left: solid 1.5px #7c40b9;
        border-radius: 0;
        margin: 8px 0;
        padding: 6px 16px;
      }
      border-bottom: solid 1px #e9ebf0;
    }
  }
  &.activityRender {
    min-width: 470px;
    .activityRender {
      &_feedback {
        background: #fffbeb;
        padding: 15px;
        p {
          font-weight: 600;
        }
        &--thumb {
          margin-left: 8px;
          margin-right: 10px;
          cursor: pointer;
          svg {
            path {
              transition: var(--transition);
            }
          }
          &:hover,
          &:active,
          &:visited {
            svg {
              // color: #fff;
              path {
                fill: var(--primary);
              }
            }
          }
        }
      }
      &_items {
        padding: 10px 20px 10px 20px;
        max-height: 400px;
        overflow-y: auto;
        &_each {
          padding: 20px 20px 15px 20px;
          border-bottom: solid 1px #f4f6f8;
          &:last-child {
            border-bottom: none;
          }
          &_top {
            h5 {
              font-size: 14px;
              font-weight: 600;
              color: #333;
              max-width: 300px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              svg {
                color: var(--primary);
                margin-right: 5px;
              }
            }
            span {
              color: var(--bs-danger);
            }
          }
          &_content {
            padding-top: 10px;

            p {
              max-width: 330px;
              font-size: 12px;
              color: #333;
            }
            &_links {
              display: flex;
              justify-content: space-between;
              button {
                border-radius: 2px;
              }
            }
          }
        }
      }
    }
  }
}

.views_select {
  .ant-badge {
    .ant-badge-dot {
      width: 12px;
      height: 12px;
    }
    &.hide .ant-badge-dot {
      display: none;
    }
  }

  &_input {
    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }

    width: 230px;
    border-radius: 4px;

    input {
      font-size: 14px;
    }

    &.updated,
    &.updated input {
      background: #f4f6f8;
    }

    &.edit {
      button {
        height: 24px;
        padding: 0 8px;
      }
    }
  }

  &_dropdown {
    width: 500px;
    font-size: 12px;
  }

  &_search {
    padding: 16px;
    display: flex;
    align-items: center;

    input {
      font-size: 12px;
    }

    button {
      margin-left: 8px;
      font-size: 24px;
      line-height: 10px;
      padding: 11px;
    }
  }

  &_content {
    padding: 0px;
    display: grid;
    grid-template-columns: auto 1fr;

    &_menu {
      width: 200px;
      li.ant-menu-item {
        margin: 0 !important;
        padding: 16px;
        height: 54px;
        font-size: 12px;

        .ant-menu-item-icon svg {
          width: 16px;
          height: 16px;
        }

        &:hover {
          background: #efeffd;
        }
      }
    }

    &_list {
      color: #54575d;
      font-size: 12px;
      height: 162px;
      overflow-y: auto;

      .ant-list-item {
        border-bottom: none;
        padding: 8px 16px 8px 24px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &.selected {
          background: #f0f4f8;
        }

        .views_select_input {
          width: 100%;
        }

        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          white-space: nowrap;
          flex: 1;
          font-size: 12px;
          line-height: 24px;
        }

        .actions {
          display: none;

          svg {
            cursor: pointer;
          }

          .ant-badge-dot {
            width: 8px;
            height: 8px;
          }

          .ant-badge.hide .ant-badge-dot {
            display: none;
          }
        }

        &:hover {
          background: #f4f6f8;

          .actions {
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }
      }
    }
  }

  &_kebab_menu {
    &:hover {
      color: #5000a2;
    }
    .ant-dropdown-menu-item {
      border: none !important;
      font-size: 12px;
    }
  }
}

.pv-filter {
  width: 760px;
  padding: 5px;

  &-row {
    display: flex;
    flex-flow: row nowrap;
  }

  &-column {
    display: flex;
    flex-flow: column nowrap;
  }

  &-field-descriptor {
    font-size: 10px;
    letter-spacing: 0.004em;
    color: #666666;

    margin-bottom: 4px;
  }

  &-reset {
    color: #7d828c;
    &:hover {
      cursor: pointer;
      color: #5000a2;
    }
  }

  &-btn {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  &-add-filter {
    width: 72px;
    height: 34px;

    margin-left: 22px;

    font-size: 20px;
    font-weight: 400;
    color: #7d828c;
  }

  &-trash {
    width: 34px;
    height: 34px;
    margin-top: 20px;
  }

  .ant {
    &-input {
      width: 269px;
      border-radius: 4px;

      &:hover {
        border-color: #e0e0e0;
        background: #f5f5f5;
      }
    }
    &-select {
      width: 164px;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.004em;
      color: #1b1b1c;

      .ant-select-selector {
        border-radius: 4px;
        border-color: #e0e0e0;

        &:hover {
          border-color: #e0e0e0;
          background: #f5f5f5;
        }
      }
    }
  }
}

#pv-filter {
  &-value {
    .ant-select {
      width: 269px !important;
    }
    .ant-picker-input {
      width: 245px !important;
    }
  }
}

.pip {
  &_col {
    &--item {
      .ant-dropdown-trigger {
        &.ant-dropdown-open {
          color: var(--primary);
          .custom_statistic {
            .ant-statistic-content {
              color: var(--primary);
            }
          }
        }
      }
    }
    &_editor {
      &.text_editor {
        background: #fff;
        border: solid 1px var(--colorAccent);
        padding: 10px 10px 5px 10px;
        border-radius: 4px;
        width: 250px;
        position: relative;
        // min-height: 50px;
        box-shadow: 1px 1px 2px -2px rgba(0, 0, 0, 0.1);
        .text_editor {
          &_bottom {
            // padding-top: 10px;
            > div {
              color: var(--colorHelper);
            }
            &--submit {
              padding: 0;
              background: none;
              border: none;
              box-shadow: none;
              outline: none;
              cursor: pointer;
              font-weight: 600;
              color: var(--secondary);
              font-size: 14px;
              transition: var(--transition);
              &:hover {
                color: var(--primary);
              }
            }
          }
        }
        &.big {
          width: 300px;
        }
      }
    }
  }
}

.direction-row {
  display: flex;
  align-items: flex-start;
  gap: 4px;

  & > *:not(.icon) {
    flex: 1;
  }

  .icon {
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f4f6f8;
    border-radius: 4px;

    svg {
      width: 28px;
      height: 28px;
    }
  }

  .ant-select .ant-select-selector {
    height: 34px;
    background: #f4f6f8;
    border: none;

    .ant-select-selection-search .ant-select-selection-search-input {
      height: 34px;
    }
  }
}

.pip_filter {
  &_creator {
    width: 740px;
    .ant {
      &-form {
        &-item {
          &-label {
            padding-bottom: 4px;
            line-height: 14px;
            > label {
              color: var(--colorHelper);
              font-size: 10px;
              line-height: 14px;
            }
          }
          &-control {
            width: 100%;
            .ant-select-selector {
              border-radius: 4px;
              border-color: var(--colorAccent);
            }
            .ant-form-item-control-input input {
              border-color: var(--colorAccent);
              border-radius: 4px;
            }
          }
        }
      }

      &-picker {
        border-color: var(--colorAccent);
        border-radius: 4px;

        input::placeholder {
          color: var(--title);
        }
      }
    }
  }

  &_box {
    display: flex;
    align-items: center;
    border: 1px dashed #c4c4c4;
    border-radius: 4px;
    background-color: #f4f6f8;
    margin: 18px 0 24px;

    &_item {
      flex: 1;
      display: flex;
      align-items: center;
      padding: 1px 16px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      white-space: nowrap;

      .ant-form-item {
        margin: 0;
        margin-right: 16px;
      }

      &:not(:last-child) {
        border-right: 1px dashed #c4c4c4;
        padding-right: 24px;
      }

      &:not(:first-child) {
        padding-left: 24px;
      }
    }
  }
}

.invite-complete-modal {
  width: fit-content !important;

  .ant-modal-body {
    padding: 32px 26px;
  }
}

.invite-complete-modal-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 260px;

  .title {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
  }

  .description {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #7d828c;
    margin-bottom: 24px;
    text-align: center;
  }

  button {
    padding: 10px 40px;
    height: 40px;
    font-size: 14px;
    line-height: 19px;
    border-radius: 4px;
  }
}

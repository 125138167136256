#block-menu-container {
  z-index: 201;
}

.ant-modal-mask {
  z-index: 198;
}
.ant-modal-wrap {
  z-index: 199;
}

.create-modal {
  width: 70% !important;
}

.pod_drawer.activity_hub_drawer {
  height: calc(100% - 2px);
  width: 560px;
  position: absolute !important;
  z-index: 1000 !important;
  right: 0;
  top: 1px;
  border: 1px solid #e9ebf0;
  background: white;

  .pod_drawer_head {
    padding: 14px 22px !important;

    &--title {
      display: flex;
      align-items: center;
      padding-left: 16px;

      span {
        font-size: 20px !important;
        line-height: 28px !important;
        font-weight: 600 !important;
        margin-left: 16px;
      }
    }
    &--actions {
      display: flex;
      align-items: center;
      gap: 14px;

      & > span {
        cursor: pointer;
      }
    }
  }

  .pod_drawer_content {
    height: calc(100% - 56px);
    overflow-y: auto;
  }
}

.auto_generated_email {
  width: 100%;
  min-height: 100%;
  padding: 16px;

  .ant-form-item {
    margin-bottom: 15px;
  }

  .ant-form-item-label {
    padding-bottom: 5px;
  }

  .ant-form-item-label > label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    &.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
      &::before {
        display: none;
      }
      &::after {
        display: inline-block;
        margin-left: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: "*";
      }
    }
  }

  .sub-item {
    .ant-form-item-label > label {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #000000D9;
    }
  }

  .ant-form-item-control-input {
    select,
    input,
    textarea {
      border: 1px solid #e0e0e0;
      border-radius: 4px;
    }
    * {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .ant-form-item-explain {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
  }

  button {
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 5px 14px;
  }

  .email_preview {
    &_header {
      display: flex;
      align-items: center;
      gap: 26px;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      color: #7d828c;
      margin-bottom: 16px;

      .back {
        cursor: pointer;
        background: #f4f6f8;
        border-radius: 2px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &_body {
      display: flex;
      align-items: flex-start;
      padding: 16px 16px 28px;
      background: #f4f6f8;
      border-radius: 4px;
      width: 100%;
    }

    &_content {
      margin-top: 4px;
      margin-left: 10px;
      font-size: 12px;
      line-height: 17px;

      &--title {
        font-weight: 700;
        margin-bottom: 16px;
      }

      &--data {
        margin-bottom: 24px;
      }
    }

    &_actions {
      margin-top: 20px;
      display: flex;
      align-items: center;
      gap: 10px;

      button {
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }
  }
}

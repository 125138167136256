.pip {
  &_col {
    &--drag {
      cursor: grab;
      font-size: 18px;
      margin-right: 10px;
      color: var(--colorBody);
      &:hover {
        color: var(--secondary);
      }
    }
    &--dealName {
      font-weight: 600;
    }
    &_header {
      &--search {
        display: block;
        padding: 5px;
        color: var(--colorHelper);
      }
    }
    &_stage {
      .ant-rate {
        color: var(--secondaryLight);
      }
      .ant-rate-star:not(:last-child) {
        margin-right: 4px;
      }
    }
    &_egscore {
      margin: 0 auto;
      width: 100px;
      position: relative;
      z-index: 0;
      text-align: center;
      &--chart {
        width: 50px;
        display: inline-block;
        canvas {
          width: 40px;
          object-fit: contain;
        }
      }
      &--value {
        width: 45px;
        vertical-align: -6px;
        display: inline-block;
      }
    }
    &_actions {
      span {
        display: inline-block;
        border-radius: 4px;
        font-size: 16px;
        transition: var(--transition);
        cursor: pointer;
        margin: 0 -3px;
        padding: 2px 5px;
        transition: var(--transition);
        &:hover {
          color: var(--primary);
          background: var(--colorAccent);
        }
      }

      a {
        svg {
          width: 24px;
          height: 24px;
        }
      }
      span.mx-1 a svg {
        width: 20px;
        height: 20px;
      }
      span.mx-2 {
        opacity: 0.5;
        cursor: default;

        svg {
          width: 20px;
          height: 20px;
        }
      }
      span.mx-3 {
        margin: 0 -3px !important;
        opacity: 0.5;
        cursor: default;
      }
    }
    &_editor {
      &.date_picker {
        .ant-picker {
          border: none;
          background: none;
          outline: none !important;
          box-shadow: none !important;
          &-input {
            span {
              display: none !important;
            }
            &-focused {
              input {
                color: var(--primary);
              }
            }
          }
        }
        .ant-picker-focused {
          input {
            color: var(--primary) !important;
          }
        }
      }
    }
  }
  &_table {
    &_extras {
      &_actions {
        span {
          margin-right: 10px;
          display: inline-block;
          padding: 4px 5px;
          border-radius: 4px;
          &:hover,
          &.selected {
            background: var(--colorAccent);
          }
        }
      }
      &_select {
        width: 180px;
      }
      &_search {
        width: 400px;
      }
      &_addon {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        &--btn {
          margin-left: 16px;
          vertical-align: middle;
          padding: 4px 9px;
          transition: var(--transition);
          border-radius: 4px;
          border: solid 1px transparent;
          color: #666666;
          cursor: pointer;
          .fil_count {
            padding-right: 10px;
            padding-left: 3px;
            display: inline-block;
            vertical-align: middle;
          }
          &:hover,
          &.ant-popover-open,
          &.filters {
            background: var(--colorAccent);
          }
          &.filters {
            border-color: var(--colorBorder);
          }
          &.disabled {
            color: #e0e0e0;
            pointer-events: none;
          }
        }
      }
    }
    &_wrapper {
      .ant-table-thead > tr > th {
        background: none;
        font-weight: 600;
        color: #666;
        background-color: #fff;
        border-top: solid 1px var(--colorAccent);
        width: max-content;
      }
      .ant-table-tbody > tr > td {
        padding: 6px 16px;
        width: max-content;
      }
      .ant-table-thead > tr > th {
        background: none;
        font-weight: 600;
        color: #666;
        background-color: #fff;
        border-top: solid 1px var(--colorAccent);
      }
      .ant-table-tbody > tr > td {
        padding: 6px 16px;
      }
      .ant-pagination {
        li {
          border: none;
          span {
            vertical-align: 2px;
          }
          &.ant-pagination {
            // &-prev{}
            &-item {
              color: #c9c9c9;
              &-link {
                span {
                  vertical-align: 2px;
                }
              }
              &-active {
                border: none;
                color: var(--primary);
              }
            }
            // &-next{}
          }
        }
      }
      // .ant-table thead th, .ant-table tbody
      //     td
      //     {    white-space: nowrap;
      //         word-break:break-word;
      //         word-break: break-all
      //     }

      .page-navigator {
        position: relative;

        .page-navigator-list {
          width: 100%;
          display: flex;
          justify-content: center;
          font-size: 16px;
          font-family: "Open Sans", sans-serif;
          list-style-type: none;
          padding: 16px 0px;

          .page-navigator-item a {
            list-style: none;
            color: #7d828c;
            padding: 0 6px;
          }

          .page-navigator-link a {
            color: #7d828c;
            padding: 0 12px;
          }

          .page-navigator-link-disabled a {
            color: #4a4a6840;
          }

          .page-navigator-active a {
            color: #5000a2;
            font-weight: bold;
          }
        }

        .page-navigator-actions {
          position: absolute;
          right: 0;
          top: 0;
          height: 56px;
          display: flex;
          align-items: center;
          gap: 32px;
          justify-content: flex-end;

          .page-navigator-size {
            .ant-select-selector {
              border-radius: 4px;
              padding: 4px 10px 4px 16px;
              background: #f4f6f8;
              border: none;
              height: 40px;
              width: 120px;
            }
          }

          .page-navigator-page {
            display: flex;
            align-items: center;
            color: #7d828c;

            input {
              border: 1px solid #e0e0e0;
              border-radius: 4px;
              height: 40px;
              width: 70px;
              margin-left: 8px;
            }
          }
        }
      }

      .loader {
        width: 100%;
        height: 100%;
        min-height: calc(100vh - 200px);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    // .ant-pagination-item-link{}
  }
}

.filter-option:hover {
  i {
    display: inline-block !important;
  }
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: 32px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 28px;
}

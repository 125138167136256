.insight-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 24px 8px 16px;
  gap: 8px;
  height: 96px;
  background: #f4f6f8;
  border-radius: 4px;
  margin-bottom: 8px;
  margin-right: 20px;
  min-width: 260px;

  p {
    margin-bottom: 0 !important;
  }
}

.insight-title {
  // width: 126.41px;
  height: 19px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.0025em;
  color: #5000a2;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.insight-description {
  height: 16px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.004em;
  color: #1b1b1c;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.insight-value {
  // width: 88px;
  // height: 33px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  color: #1b1b1c;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.engagement-value {
  color: #0e7537;
}

.engagement-icon {
  width: 24px;
  height: 24px;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin-left: -30px;
}

.selectable {
  &_search {
    .ant-input-affix-wrapper {
      border-radius: 4px;
      border-color: var(--colorAccent);
    }
  }
  &_options {
    border: none;
    padding-top: 10px;
    max-height: 200px;
    overflow-y: auto;
    .ant-menu-item {
      border-radius: 4px;
      font-weight: 600;
      font-size: 14px;
      margin: 0;
      &:hover {
        background: var(--colorAccent);
        color: var(--colorBody);
      }
    }
  }
}

.spin {
  width: 100%;
  margin-top: 14px;
}

.display-container {
  height: 40px;
  border: solid 1px #d9d9d9;
  border-radius: 2px;
  padding: 6.5px 11px;
}

.display-value {
  line-height: 27px;
  font-size: 14px;
}

.pip_actions {
  &_insights {
    &_wrapper {
      .ant-popover-inner-content {
        padding: 0;
      }
    }
    &--title {
      padding: 16px;
      border-bottom: solid 1px #f4f6f8;
      h4 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
        span {
          color: var(--primary);
          background: var(--primaryLight);
          margin-right: 15px;
          padding: 6px 6px;
          border-radius: 4px;
          display: inline-block;
          // svg{vertical-align: -1px;}
        }
      }
    }
    &_cards {
      padding: 2px 15px 15px 15px;
      margin: 0;
      &_each {
        min-width: 330px;
        list-style: none;
        border-bottom: solid 1px #f4f6f8;
        padding: 15px 10px 10px 10px;
        border-radius: 5px;
        transition: var(--transition);
        &:last-child {
          border-bottom: none;
        }
        &:hover {
          background: #f4f6f8;
        }
        &--details {
          h6 {
            font-size: 13px;
            margin-bottom: 6px;
          }
          p {
            color: var(--colorHelper);
            font-size: 11px;
            margin: 0;
            img {
              width: 14px;
              margin-right: 5px;
            }
          }
        }
        &--user {
          text-align: right;
          span {
            color: var(--colorHelper);
            font-size: 11px;
            img {
              display: inline-block;
              width: 12px;
              display: inline-block;
              vertical-align: -1px;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}

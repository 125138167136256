.notes_assistant {
  width: 100%;
  min-height: 100%;

  &_header {
    background: #f5f5f5;
    padding: 14px 18px;
    display: flex;
    align-items: center;
    gap: 32px;

    &--tab {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border-radius: 2px;
      cursor: pointer;

      &:hover,
      &.active {
        background: #efeffd;
        color: var(--primary);
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &_content {
    height: 100%;
    min-height: 100%;
  }

  &_tab {
    margin-bottom: 40px;

    .summary_title {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #333;
      margin-bottom: 20px;
    }

    .summary_block {
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      &--title {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #7d828c;
        margin-bottom: 14px;
      }

      &--content {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #333;
      }

      &_steps {
        display: block;

        &--item {
          margin-bottom: 12px;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #1b1b1c;
        }
      }
    }

    &.followup {
      width: 100%;
      min-height: 100%;
      overflow-y: auto;
      padding: 0;
      margin: 0;

      .followup_type {
        margin-bottom: 24px;
        width: 260px;
        flex-direction: column;

        .ant-form-item-label {
          width: fit-content;
          & > label::after {
            display: none;
          }
        }
      }

      .ant-form-item {
        margin-bottom: 16px;
      }

      .ant-form-item-label > label {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        &.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
          &::before {
            display: none;
          }
          &::after {
            display: inline-block;
            margin-left: 4px;
            color: #ff4d4f;
            font-size: 14px;
            font-family: SimSun, sans-serif;
            line-height: 1;
            content: "*";
          }
        }
      }

      .sub-item {
        .ant-form-item-label > label {
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          color: #666;
        }
      }

      .ant-form-item-control-input {
        select,
        input,
        textarea {
          border: 1px solid #e0e0e0;
          border-radius: 4px;
        }
        * {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
        }
      }

      .ant-form-item-explain {
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
      }

      button {
        border-radius: 4px;
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 5px 14px;
      }

      .email_preview {
        &_header {
          display: flex;
          align-items: center;
          gap: 26px;
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          color: #7d828c;
          margin-bottom: 16px;

          .back {
            cursor: pointer;
            background: #f4f6f8;
            border-radius: 2px;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        &_body {
          display: flex;
          align-items: flex-start;
          padding: 16px 16px 28px;
          background: #f4f6f8;
          border-radius: 4px;
          width: 100%;
        }

        &_content {
          margin-top: 4px;
          margin-left: 10px;
          font-size: 12px;
          line-height: 17px;

          &--title {
            font-weight: 700;
            margin-bottom: 16px;
          }

          &--data {
            margin-bottom: 24px;
          }
        }

        &_actions {
          margin-top: 20px;
          display: flex;
          align-items: center;
          gap: 10px;

          button {
            display: flex;
            align-items: center;
            gap: 12px;
          }

          .google-gmail {
            border-color: #1960e2;
            color: #1960e2;
          }
        }
      }
    }

    &.next_steps {
      .next_steps_block {
        display: flex;
        flex-direction: column;
        gap: 30px;
      }

      .next_steps_item {
        display: flex;
        align-items: flex-start;
        width: 100%;
        cursor: pointer;

        &--check {
          flex: 1;

          .ant-checkbox-inner {
            border-color: var(--primary);
            border-radius: 4px;
          }

          & > span:last-child {
            padding: 0px 16px;
          }

          &.checked > span:last-child {
            color: #7d828c;
            text-decoration: line-through;
          }
        }

        &--add {
          color: #7d828c;
        }

        &:hover {
          .next_steps_item--add {
            color: var(--primary);
          }
        }
      }
    }

    &.updated_fields {
      background: transparent;

      .updated_fields_record {
        border-color: #e9ebf0;

        &--name {
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
        }

        .ant-collapse-header {
          padding: 16px 0px;
          flex-direction: row-reverse;
          align-items: center;

          .ant-collapse-expand-icon {
            padding: 4px;
            width: 24px;
            height: 24px;
            transition: transform 0.2s ease;

            svg {
              margin: 0;
            }
          }

          .ant-collapse-header-text {
            display: flex;
            align-items: center;
            gap: 4px;
            flex: 1;
          }
        }

        &.ant-collapse-item-active .ant-collapse-expand-icon {
          transform: rotate(-180deg);
        }

        .ant-collapse-content-box {
          padding: 4px 0px 16px;
        }

        &--items {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
      }

      .updated_fields_field {
        display: flex;
        border-radius: 8px;
        padding: 10px 6px 10px 12px;

        &:hover {
          background: #f4f6f8;
        }

        &--block {
          display: flex;
          flex-direction: column;
          gap: 3px;
          flex: 1;
        }

        &--title {
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          color: #7d828c;
        }

        &--value {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
        }

        &--action {
          svg {
            cursor: pointer;
            color: #7d828c;
            &:hover {
              color: var(--primary);
            }
          }
        }
      }
    }
  }
}

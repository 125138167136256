.input-checkbox {
  // Hide the browser's default checkbox
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked {
    & + .checkbox-container {
      background-color: #efeffd;
      border-color: #5000a2;

      & .checkbox {
        background-color: #5000a2;
        border-color: #5000a2;
      }

      & .checkbox:after {
        display: block;
      }
    }
  }
}

.checkbox-container {
  display: table;
  width: 474px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-top: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  // Create our own custom checkbox
  .checkbox {
    width: 28px;
    height: 28px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;

    &:after {
      content: "";
      display: none;
    }
  }

  &:hover {
    background-color: #efeffd;
    border-color: #5000a2;

    .checkbox {
      background-color: white;
    }
  }

  .checkbox:after {
    background-image: url("../../../Images/TickWhite.svg");
    background-repeat: no-repeat;
    position: relative;
    left: 5px;
    top: 7px;
    width: 28px;
    height: 28px;
  }
}

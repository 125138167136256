.invite-modal {
  .ant {
    &-modal {
      &-header {
        background: linear-gradient(90deg, #f8f0fe 0%, #e4eefd 100%);
        border-radius: 12px;
      }
      &-content {
        border-radius: 12px;
      }
    }
  }

  p {
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 0.004em;
    color: #666666;
    margin-bottom: 0;
  }

  &-column {
    display: flex;
    flex-flow: column nowrap;
  }

  &-row {
    display: flex;
    flex-flow: row nowrap;
  }

  &-btn {
    height: 40px;
    width: 153px;
    line-height: 34px;
    border-radius: 4px;
    border-color: #5000a2;
    background-color: #5000a2;

    color: white;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.0025em;
  }

  &-input {
    height: 60px;
    width: 474px;
    background: white;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    padding: 27px 24px 26px 24px;
    margin-bottom: 20px;

    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.0025em;
    color: #1b1b1c;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #c4c4c4;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.0025em;
    }
  }
}

.close-icon:hover {
  filter: invert(17%) sepia(91%) saturate(6005%) hue-rotate(273deg) brightness(61%) contrast(131%);
}

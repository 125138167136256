::-webkit-calendar-picker-indicator {
  margin-left: -15px;
}
.google-icon {
  width: 160px;
  height: auto;
  cursor: pointer;
}

.syn_btn2 {
  height: 35px;
  padding: 0 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #0085f7;
  color: #fff;
  border-radius: 4px;
  border: none;
}

.short-desc-for-google-login {
  //styleName: Medium - 13 - 500;
  width: 245px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.004em;
  text-align: center;
  color: #000000;
}
.google-login-modal .ant-modal-content {
  border-radius: 12px !important;
}

.common_aside,
.common_header {
  z-index: 8;
}

.weekly-picker {
  .ant-picker-clear,
  .ant-picker-suffix {
    display: none !important;
  }
}

.weekly-table {
  .antd-table-custom-class thead th,
  .antd-table-custom-class tbody td {
    white-space: pre-wrap;
    text-overflow: ellipsis;
  }

  /* row data */
  .ant-table-tbody > tr > td {
    height: 61px;
    width: 101px;
    padding: 4px;
  }

  .ant-table-thead > tr > th {
    height: 61px;
    width: 101px;
    padding: 4px;
  }

  .ant-table-body {
    max-height: calc(100vh - 320px) !important;
  }
  .ant-table.ant-table-bordered > .ant-table-container {
    border-left: none;
    border-top: none;
  }
  .ant-table-thead {
    .ant-table-cell {
      text-align: center;
      padding-top: 12px;
    }
    .ant-table-cell:first-child {
      width: 10%;
      border-bottom: none;
      font-weight: normal;
      font-style: normal;
      font-size: 10px;
      line-height: 14px;
      font-family: "Open Sans", sans-serif;
      text-align: right;
      letter-spacing: 0.004em;
      background: none;
    }
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > thead
    > tr
    > th {
    border-right: 0.5px solid #e0e0e0;
  }
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
    border: none;
  }
  /* row data */
  .ant-table-tbody > tr > td {
    height: 61px;
    width: 101px;
    padding: 4px;
  }

  .ant-table-thead > tr > th {
    height: 61px;
    width: 101px;
    padding: 4px;
  }
}

.round-date {
  background-color: #5000a2;
  color: white;
  border-radius: 50%;
  width: 32px;
  margin: auto;
}

.event-block:hover {
  background-color: #efeffd !important;
}

.text-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.ant-modal-mask {
  top: 0;
  left: 0;
}

.ant-modal-wrap {
  top: 0;
  left: 0;
}

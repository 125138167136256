.actions-dropdown-item {
  cursor: pointer;
  margin: 0 !important;
  border-radius: 2px;
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e9ebf0;
  padding: 8px;
  color: #54575d;

  span {
    :hover {
      border-radius: 4px;
      background: #add8e680;
    }
  }
}

.actions-dropdown-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  svg {
    width: 24px;
    height: 24px;
  }
}

.actions-dropdown-icon {
  svg {
    width: 24px;
    height: 24px;
  }
}

.actions-dropdown-item:hover {
  background-color: #add8e640;
}

.options-item-text {
  font-size: 12px;
  line-height: 16px;
  padding-right: 16px;
  flex: 1;
  margin: 0;
}

.no-result {
  padding: 10px;
  text-align: center;
}

.spin {
  width: 100%;
  margin: 10px 0;
}

.ant-dropdown-menu {
  padding: 0 !important;
  border-radius: 4px;
}

.resized-icon {
  svg {
    width: 14px;
    height: 14px;
  }
}

.resized {
  width: 100px;
}

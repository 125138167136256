.notes_header {
  display: flex;
  align-items: center;

  &_back {
    cursor: pointer;
    width: 24px;
    height: 24px;
    padding: 4px;
    background: #f4f6f8;
    border-radius: 2px;
    display: inline-block;
    margin-right: 8px;

    svg {
      width: 16px;
      height: 16px;
      color: #54575d;
      display: block;
    }
  }
}

.loader {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectbox-cstm .ant-select-selector {
  background-color: #f5f5f5 !important;
  border: none !important;
}

.title_input_box {
  font-size: 20px;
  ::placeholder {
    color: #7d828c;
    font-weight: 400;
  }
}

.ck-editor__editable_inline {
  min-height: calc(100vh - 155px);
  border: none;
}

.cstm-selectbox .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: #1b1b1c !important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px !important;
}

.ck.ck-editor__top.ck-reset_all {
  z-index: var(--ck-z-modal);
  position: sticky;
  top: 0;
}

.ck.ck-sticky-panel__placeholder {
  display: none !important;
}

.ck.ck-editor {
  z-index: 0;
}

.ck.ck-sticky-panel .ck-sticky-panel__content_sticky {
  position: unset;
}

.notes_wrapper {
  margin: 0px -16px;
  width: calc(100% + 32px);

  &_subheader {
    position: absolute;
    right: 0px;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &--icon {
      position: relative;
      width: 26px;
      height: 26px;
      margin-left: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      line-height: initial;
      padding: 4px;
      border-radius: 2px;
      color: #777;

      &:hover {
        color: var(--primary);
        background: var(--primaryLight);
      }

      div > svg {
        width: 26px;
        height: 26px;
      }
    }
  }

  &_content {
    display: flex;
    justify-content: space-between;
  }

  &_form {
    width: 100%;
    padding: 0px 64px 32px;

    &.left {
      width: fit-content;
    }
  }

  &_assistant {
    position: fixed;
    height: calc(100vh - 128px);
    top: 128px;
    right: 4px;

    .pod_drawer.activity_hub_drawer {
      width: 376px;
      .pod_drawer_head--title {
        padding: 0;
      }
    }
  }
}

.doc_wrapper {
  margin: 8px auto;
  width: 640px;

  &_title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .title_input_box {
      flex: 1;
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      border: none;
      outline: none;
      padding: 4px;

      &:focus {
        box-shadow: none;
      }
    }

    &_sync {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-left: 24px;

      button {
        background: rgba(0, 161, 224);
        border-radius: 4px;
        color: white;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        padding: 6px 8px;
        height: unset;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: none;

        img {
          margin-right: 8px;
        }

        &:disabled {
          background: rgba(0, 161, 224, 0.3);
          color: white;
        }
      }
    }

    &_timestamp {
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      height: 14px;
      color: #666;
      margin-top: 4px;
      white-space: nowrap;
    }
  }

  &_editor {
    .cke {
      border: none;
      border-radius: 4px;
      border-color: #e0e0e0;
      &_inner,
      &_contents {
        border-radius: 5px;
      }
      &_bottom {
        display: none;
      }
    }
    .ck-editor__main {
      height: calc(100vh - 158px);
      overflow: auto;
    }
  }

  &_meta {
    width: 100%;
    margin-top: 16px;

    &_title {
      width: 100%;
      // background: #F8F0FE;
      padding: 12px 16px;
      height: 54px;
      background: linear-gradient(90deg, #f8f0fe 0%, #e4eefd 100%);
      > span {
        line-height: 29px;
      }
    }

    &_content {
      height: unset;
      transition: var(--transition);

      &.collapsed {
        height: 0px;
        overflow: hidden;
      }

      &_section {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 8px;
      }

      &_item {
        display: flex;
        align-items: flex-start;
        margin-top: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
      }

      &_title {
        width: 40%;
        padding: 8px;
        color: #666666;
        display: flex;
        align-items: center;

        .title_icon {
          margin-right: 8px;

          svg {
            display: block;
            width: 16px;
            height: 16px;
          }

          .sf {
            & > div {
              width: 20px;
              height: 20px;
            }
            svg {
              width: 20px;
              height: 20px;
            }
          }
        }

        .title_label {
          font-weight: 500;
        }
      }

      &_value {
        padding: 8px 16px;
        font-weight: 500;
        color: #24282d;
        max-width: 60%;

        &.editable {
          cursor: pointer;
          &:hover {
            background: #e9ebf0;
            border-radius: 4px;
          }
        }
      }

      &_doctype {
        border: none;
        // height: "100px",
        border-bottom: solid 0.5px #e0e0e0;
      }
      &_meeting {
        border: none;
        border-bottom: solid 0.5px #e0e0e0;
        .datepicker-cls {
          input {
            font-size: 12px;
          }
        }
      }
      &_sf {
        border: none;
        border-bottom: solid 0.5px #e0e0e0;
        &_select {
          margin: 10px 0;
          > span {
            width: 32px;
            height: 32px;
            display: inline-block;
            margin-right: 8px;
          }
          .cstm-selectbox {
            width: 240px;
          }
        }
      }
      &_stake {
        border: none;
        border-bottom: solid 0.5px #e0e0e0;
      }
    }

    &_divider {
      height: 1px;
      margin: 16px 0;
      flex: 1;
      background: #e0e0e0;
    }

    &_toggle {
      margin: 32px 0 16px;
      display: flex;
      align-items: center;

      &.collapsed {
        margin: 4px 0 16px;
        svg {
          transform: rotate(-180deg);
        }
      }
    }

    &_back {
      width: 24px;
      height: 24px;
      margin-left: 8px;
      background: #f4f6f8;
      border-radius: 4px;
      cursor: pointer;
      padding: 4px;

      svg {
        width: 16px;
        height: 16px;
        display: block;
      }
    }

    &_select {
      width: 350px !important;
      padding: 0;
      .ant {
        &-select {
          &-selector {
            height: 35px !important;
            border-radius: 4px !important;
            font-size: 14px !important;
            line-height: 19px !important;
            font-weight: 500;
            padding: 7px 15px !important;

            input {
              height: 33px !important;
            }

            .ant-select-selection-search {
              left: 15px;
            }
          }

          &-selection-placeholder {
            line-height: 19px !important;
          }

          &-selection-item {
            font-size: 14px !important;
            line-height: 19px !important;
            font-weight: 500;
          }
        }
      }

      &.calendar-view_add_participant_box.ant-select-multiple {
        width: 60% !important;
        .ant-select-selector {
          height: unset !important;
          padding: 4px 7px !important;
        }

        .ant-select-selection-item {
          background: white;
          border: none;
          border-radius: 4px;
          display: flex;
          align-items: center;

          &-content {
            line-height: 24px;
            display: flex;
            align-items: center;
            svg {
              width: 16px;
              height: 16px;
              margin-right: 4px;
              display: block;
            }
          }

          &-remove {
            display: flex;
            align-items: center;
          }
        }

        .ant-select-selection-search {
          left: 0px;
        }

        input {
          height: 28px !important;
        }
      }

      &_values {
        display: flex;
        flex-wrap: wrap;

        & > div {
          margin-bottom: 8px;
          margin-right: 16px;
          display: flex;
          align-items: center;

          svg {
            width: 16px;
            height: 16px;
            margin-right: 4px;
            display: block;
          }
        }
      }
    }
  }
}

.calendar-view_add_participant_item {
  display: flex;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    display: block;
  }
}

.doc_wrapper_right_content_sf_select {
  flex-direction: row;
  display: flex;
}

.editor-content {
  width: 100%;
  margin: 32px 0px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 !important;
  }

  * {
    max-width: 100%;
  }
}

.template-select {
  a {
    color: #bebfbf !important;
    width: 100%;
    line-height: 40px;
    padding: 8px;
  }
  p {
    overflow-x: hidden;
  }
  a:hover {
    background-color: #add8e640;
  }
}

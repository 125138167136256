.common_header {
  &_task {
    &--btn {
      background-color: #fff;
      color: var(--primary);
      font-size: 1rem;
      padding: 10px 15px;
      border-radius: 6px;
      height: 36px;
      //   width: 36px;
      line-height: 1px;
      font-weight: 600;
      cursor: pointer;
      text-align: center;
      transition: var(--transition);
      border-color: #fff;
      &:hover,
      &:focus {
        background-color: var(--secondary);
        border-color: var(--secondary);
        color: #fff;
      }
    }
  }
  &_wrp_right {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .custom_btn {
      margin-right: 24px;
      border-radius: 4px;
    }

    .commandbar_launcher {
      color: #7d828c;
      border-radius: 4px;
      padding: 5px 6px 5px 15px;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      margin-right: 10px;
      cursor: pointer;
      border: 1px solid #e0e0e0;
      background: white;

      .shortcuts {
        margin-left: 20px;
        background: var(--primaryLight);
        border-radius: 4px;
        padding: 3px 6px;
        font-size: 12px;
        line-height: 16px;
        color: #24282d;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 10px;
          height: 10px;
          margin-right: 5px;
          color: inherit;
        }
      }

      &:hover {
        border-color: var(--primary);
        color: #24282d;

        .shortcuts {
          background: var(--primary);
          color: white;
        }
      }
    }

    &--icon {
      display: inline-block;
      line-height: initial;
      padding: 5px 6px;
      border-radius: 3px;
      margin-right: 12px;

      cursor: pointer;
      &:hover,
      &.ant-dropdown-open,
      &.ant-popover-open {
        color: var(--primary);
        background: var(--primaryLight);
      }

      &--disabled {
        display: inline-block;
        line-height: initial;
        padding: 5px 6px;
        border-radius: 3px;
        margin-right: 12px;

        opacity: 0.5;
        //cursor: not-allowed;
      }
    }
  }
}

.user-dropdown {
  background: white;
  padding: 8px;
  border-radius: 4px;

  &-parent {
    @extend .user-dropdown;

    border: 1px solid #e0e0e0;
    box-shadow: var(--boxshadow);
    width: 180px;
  }

  &-child {
    @extend .user-dropdown;

    height: 40px;
    cursor: pointer;

    p {
      font-size: 14px;
      margin-left: 20px;
      letter-spacing: 0.1px;
      color: #1b1b1c;
    }

    img {
      height: 17px;
    }

    &:hover {
      background: #f4f6f8;
    }
  }
}

.knowledge_center {
  width: 100%;
  height: 100%;

  .ant-tabs-nav {
    &::before {
      background: #e9ebf0;
      height: 2px;
      border: none;
    }
    margin-bottom: 0px;
  }

  .ant-tabs-tab {
    font-size: 14px;
    line-height: 19px;

    & + .ant-tabs-tab {
      margin-left: 20px;
    }
  }

  .ant-tabs.ant-tabs-top {
    height: 100%;
  }

  .ant-tabs-content-holder {
    padding: 16px 25px;
    height: calc(100% - 54px);
    overflow-y: auto;

    .ant-tabs-content.ant-tabs-content-top {
      height: unset !important;
      min-height: 100%;
    }
  }

  .pod_drawer_tab {
    margin-bottom: 40px;

    .summary_title {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #333;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      gap: 12px;

      img {
        height: 12px;
      }
    }

    .summary_block {
      &:not(:last-child) {
        margin-bottom: 40px;
      }

      &--title {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #7d828c;
        margin-bottom: 14px;
      }

      &--content {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #333;
      }

      &_serving {
        gap: 10px;
        flex-wrap: wrap;

        &--tab {
          background: #e9ebf0;
          border-radius: 2px;
          padding: 8px 14px;
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          color: #24282d;
          cursor: pointer;

          &:hover {
            background: #f4f6f8;
          }
        }

        &--plus {
          background: #f4f6f8;
          border-radius: 8px;
          padding: 8px 14px;
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          color: #24282d;
          cursor: pointer;
          margin-top: 8px;

          &:hover {
            background: #e9ebf0;
          }
        }
      }

      &_about {
        background: #f4f6f8;
        border-radius: 8px;
        padding: 16px 40px 16px 32px;
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;

        &--tab {
          display: flex;
          align-items: center;

          &:last-child {
            grid-column: span 2;
          }
        }

        &--icon {
          color: var(--primary);
          margin-right: 8px;
        }

        &--title {
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          color: #7d828c;
        }

        &--value {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #24282d;
        }
      }

      &_fundraising {
        flex-direction: column;

        &--item {
          width: 100%;
          padding: 13px 32px;
          background: #f4f6f8;
          border-radius: 8px;
          display: grid;
          grid-template-columns: repeat(3, 1fr);

          &:not(:first-child) {
            margin-top: 8px;
          }
        }

        &--series {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
        }

        &--amount {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: var(--primary);
          text-align: center;
        }

        &--timestamp {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #7d828c;
          text-align: right;
        }
      }

      &_competitors {
        flex-direction: column;
        align-items: flex-start;

        &--item {
          width: 100%;
          padding: 12px 48px 16px 18px;
          background: #f4f6f8;
          border-radius: 8px;
          position: relative;

          &:hover {
            cursor: pointer;
          }

          &:not(:first-child) {
            margin-top: 8px;
          }
        }

        &--title {
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #24282d;
        }

        &--summary {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          margin-top: 14px;
          color: #54575d;
        }

        &--homepage {
          position: absolute;
          color: #54575d;
          right: 22px;
          top: 12px;
        }
      }

      &_links {
        gap: 50px;
        &--item {
          color: #54575d;
          cursor: pointer;
          &:hover {
            color: var(--primary);
          }
        }
      }
    }

    &.ask_anything {
      display: flex;
      flex-direction: column;
      height: 100%;

      .ask_input {
        padding: 10px 0px 0px;

        &--group {
          background: #f4f6f8;
          border-radius: 4px;
          padding: 7px 10px;
          display: flex;
          align-items: flex-start;
          gap: 12px;

          & > * {
            min-height: 32px;
          }

          textarea {
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
            background: transparent;
          }

          button {
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .ask_contents {
        flex: 1;
        max-height: 100%;
        overflow-y: auto;

        &_loading {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &_placeholder {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          margin-top: 120px;

          &--title {
            margin-bottom: 32px;
            display: flex;
            align-items: center;
            gap: 10px;
          }

          &--queries {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 16px;
            flex-wrap: wrap;
            padding: 0px 60px;
          }

          &--query {
            background: #ffffff;
            border: 1px solid #e0e0e0;
            border-radius: 5px;
            padding: 6px 10px;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #2c2c2c;
            cursor: pointer;

            &:hover {
              border-color: var(--primary);
              color: var(--primary);
            }
          }
        }

        &_item {
          padding: 10px;
          display: flex;
          align-items: flex-start;
          border-radius: 4px;

          &.answer {
            background: #f4f6f8;
            .caret {
              width: 1px;
              border-right: 1px solid transparent;
              margin-left: 2px;
              color: transparent;
              animation: caret-animation 1.2s ease-out forwards infinite;
            }
          }

          &--avatar {
            border-radius: 50%;
            background: #1bb7e9;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-weight: 600;
            font-size: 8px;
            line-height: 11px;
          }

          &--content {
            flex: 1;
            margin: 2px 8px 4px;
            color: #24282d;
            white-space: wrap;
          }

          &--action {
            cursor: pointer;
          }
        }
      }
    }

    &.news {
      min-height: 100%;

      .news_mode {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 20px;

        .ant-tabs {
          &-tab {
            padding-top: 8px;
            width: 26px;
            height: 26px;
            &-active {
              color: var(--primary);
              background: var(--primaryLight);
            }
          }

          &-ink-bar {
            background-color: transparent;
          }

          &-nav {
            padding: 0 0 16px 0;
            &::before {
              display: none;
            }
          }

          &-content-holder {
            padding: 0;
          }
        }

        &--item {
          cursor: pointer;
          width: 26px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2px;
          &:hover {
            color: var(--primary);
            background: var(--primaryLight);
          }
        }
      }

      .news_summary {
        display: flex;
        align-items: flex-start;
        padding: 16px 16px 28px;
        background: #f4f6f8;
        border-radius: 4px;
        width: 100%;

        &_content {
          margin-top: 4px;
          margin-left: 10px;
          font-size: 12px;
          line-height: 17px;

          &--title {
            font-weight: 700;
            margin-bottom: 16px;
          }

          &--data {
            margin-bottom: 24px;
          }

          &--subtitle {
            font-weight: 400;
            font-size: 10px;
            line-height: 14px;
            color: #7d828c;
          }

          &--sources {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 24px;
            margin-top: 4px;

            & > * {
              font-weight: 400;
              font-size: 10px;
              line-height: 14px;
              color: var(--primary);
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }

      .news_list {
        &_item {
          position: relative;
          padding: 12px 16px 16px;
          cursor: pointer;

          &:not(:last-child) {
            margin-bottom: 16px;
          }

          &:hover {
            background: #f4f6f8;
            border-radius: 4px;

            .news_list_item--title {
              color: var(--primary);
            }
          }

          &--sentiment {
            position: absolute;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            top: 13px;
            right: 20px;
            background: #31d0aa;
          }

          &--head {
            font-weight: 400;
            font-size: 10px;
            line-height: 14px;
            color: #7d828c;
            margin-bottom: 8px;
          }

          &--title {
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
          }

          &--content {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #7d828c;
            margin-top: 8px;
          }

          &--divider {
            height: 1px;
            background: #e9ebf0;
            position: absolute;
            bottom: -8px;
            left: 16px;
            right: 16px;
          }
        }
      }
    }
  }
}

.ask_contents_feedback {
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 19px 20px;
  position: relative;

  &--close {
    position: absolute;
    color: #7d828c;
    top: 20px;
    right: 16px;
    cursor: pointer;
  }

  &--title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin-right: 24px;
    margin-bottom: 14px;
  }

  &--submit {
    display: flex;
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
      color: var(--primary);
      width: 24px;
      height: 24px;
    }
  }

  &--submitted {
    margin-top: 14px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }

  &--radios {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    gap: 16px;

    .ant-radio-wrapper {
      color: #7d828c;

      &.ant-radio-wrapper-checked {
        color: #1b1b1c;
        font-weight: 600;
      }
    }
  }
}

@keyframes caret-animation {
  50% {
    border-color: black;
  }
} ;

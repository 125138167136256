.arrow-img {
  width: 12px;
  height: 18px;
  background-repeat: no-repeat;

  &-left {
    @extend .arrow-img;
    margin-right: 20px;
    background-image: url("../../../Images/ArrowLeftPurple.svg");
  }

  &-right {
    @extend .arrow-img;
    margin-left: 20px;
    background-image: url("../../../Images/ArrowRightPurple.svg");
  }
}

.dot {
  height: 8px;
  width: 8px;
  background-color: #d3bfe8;
  border-radius: 50%;
  margin: 2.5px 4px;
}
